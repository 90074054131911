var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-wrapper" }, [
    _c("div", { staticClass: "columns is-gapless" }, [
      _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
      _c("div", { staticClass: "column" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            !_vm.passes || _vm.passes.length == 0
              ? _c("h1", [_vm._v("Claim Your Pass!")])
              : _vm._e(),
            _vm.passes && _vm.passes.length > 0
              ? _c("h1", [_vm._v("My Passes")])
              : _vm._e(),
            _c("p", [_vm._v(_vm._s(_vm.statusMessage))]),
            _vm._l(_vm.passes, function (pass) {
              return _c("div", { key: pass.id }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-narrow" }, [
                    _c("a", { attrs: { href: pass.festivalImage } }),
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "eventTitle" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "festival",
                                params: { festivalslug: "tchfxii" },
                              },
                            },
                          },
                          [_vm._v(_vm._s(pass.festivalName))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" " + _vm._s(pass.passname) + " "),
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c("strong", [_vm._v("Used:")]),
                    _vm._v(
                      " " +
                        _vm._s(pass.usedCount) +
                        "/" +
                        _vm._s(
                          pass.passType == "unlimited"
                            ? "unlimited"
                            : pass.limit
                        )
                    ),
                  ]),
                ]),
                _c(
                  "table",
                  { staticClass: "table tixtable" },
                  [
                    _vm._m(0, true),
                    _vm._l(pass.uses, function (use, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _vm._v(_vm._s(use.date) + " " + _vm._s(use.time)),
                        ]),
                        _c("td", [_vm._v(_vm._s(use.eventTitle))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(use.quantity) +
                              " - " +
                              _vm._s(use.description)
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ])
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date and time")]),
        _c("th", [_vm._v("Show")]),
        _c("th", [_vm._v("Tickets")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }